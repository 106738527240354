/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

var Trix = require("trix")
require("@rails/actiontext")

// Custom buttons
Trix.config.textAttributes.red = {
  style: { color: 'red' },
  parser: function (elem) {
    return elem.style.color === 'red'
  },
  inheritable: true
}
Trix.config.textAttributes.blue = {
  style: { color: 'blue' },
  parser: function (elem) {
    return elem.style.color === 'blue'
  },
  inheritable: true
}
Trix.config.textAttributes.green = {
  style: { color: 'green' },
  parser: function (elem) {
    return elem.style.color === 'green'
  },
  inheritable: true
}

addEventListener('trix-initialize', function (event) {
  event.target.toolbarElement.querySelector('.trix-button-group--text-tools').insertAdjacentHTML('beforeend', '<button type="button" class="trix-button" data-trix-attribute="red">RED</button>');
  event.target.toolbarElement.querySelector('.trix-button-group--text-tools').insertAdjacentHTML('beforeend', '<button type="button" class="trix-button" data-trix-attribute="blue">BLUE</button>');
  event.target.toolbarElement.querySelector('.trix-button-group--text-tools').insertAdjacentHTML('beforeend', '<button type="button" class="trix-button" data-trix-attribute="green">GREEN</button>');
})
